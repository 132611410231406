// Couldn't figure out how to get AsyncLocalStorage / async_hooks to run in NextJs middleware.
// Problems:
//   1. Middleware uses the Edge runtime which doesn't support AsyncLocalStorage.
//   2. Middleware context is different than the API context, a global var in middleware is not accessible to the logger.
//
// So, here is a global variable that we import and use at API runtime to hold the request ID.

const store: Map<string, string> = new Map<string, string>();

const ctx = {
  getStore: () => store,
};

export default ctx;
