import ctx from "../context";

type LogFn = (stage: string, msg: string, value: any) => void;

export type Logger = {
  atDebug: LogFn;
  atInfo: LogFn;
  atWarn: LogFn;
  atError: LogFn;
};

const emptyRequestId = "---";
const getRequestScoper = (stage: string, pretext: string) => {
  const requestId = ctx.getStore()?.get("requestId") || emptyRequestId;
  const log = `${new Date()} [Req: ${requestId}][${stage}] ${pretext}`;

  if (requestId === emptyRequestId) {
    console.warn(
      `${new Date()} [${stage}] ${pretext} Logger warning: missing request id`,
    );
  }

  return log;
};

const getLogFn =
  (baseLogFn: (a: any, b: any) => void): LogFn =>
  (stage: string, pretext: string, value: any) =>
    baseLogFn(getRequestScoper(stage, pretext), value);

/**
 * A helper util to log logs in a nice format, including a requestId, a stage and a message.
 *
 * @param param0 Options for the Logger
 * @returns Logger
 *
 * @example
 * import { logger } from "../util"
 * logger.atInfo("Startup", "App is starting", "10am");
 * ==> void
 * ==> logs this at INFO: "[Req: abc][Startup] App is starting 10am"
 */
export const getLogger = (): Logger => {
  return {
    atDebug: getLogFn(console.debug),
    atInfo: getLogFn(console.debug),
    atWarn: getLogFn(console.debug),
    atError: getLogFn(console.debug),
  };
};

export const logger = getLogger();

export const logInitiation = (actionName: string, params: any): void => {
  logger.atDebug("[Initiated with params]", actionName, params);
};

type Instrumenter = {
  start: () => void;
  finish: () => void;
};

/**
 * A helper to generate an object that will log start, end and duration.
 *
 * @param processName The stage to log in the logs for the instrumentation
 * @returns an Instrumenter to be used to log timing
 *
 * @example
 * import { getInstrumenter } from "../util"
 * const instrumenter = getInstrumenter("someMath");
 * instrumenter.start();
 * // console.debug("[Req: abc][someMath] Started at: ...")
 * const a = 100 + 100 * 100;
 * instrumenter.finish();
 * // console.debug("[Req: abc][someMath] Finished at:...")
 * // console.debug("[Req: abc][someMath] Duration (ms):...")
 */
export const getInstrumenter = (processName: string): Instrumenter => {
  let startedTime: [number, number] = [0, 0];
  let started: boolean = false;

  return {
    start: () => {
      startedTime = process.hrtime();
      logger.atDebug(processName, "Started", "");
      started = true;
    },
    finish: () => {
      const completedTime = process.hrtime(startedTime);

      if (!started) {
        logger.atWarn(
          processName,
          "Instrumenter warning",
          "Instrumenter for process" +
            processName +
            "was finished without being started.",
        );
        return;
      }

      logger.atDebug(
        processName,
        "Completed in",
        `${completedTime[0]}s ${(completedTime[1] / 1000000).toFixed(1)}ms`,
      );
      started = false;
    },
  };
};
