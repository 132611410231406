import { AssetType } from "model/aggregations";
import { Granularity } from "model/aggregations/nfts";
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { actionCreator } from "src/reducer/reducer-util";
import { SUB_TYPE, WSServerGranularities } from "src/types/candlestick.types";
export type WebSocketStatus =
  | "None"
  | "Closed"
  | "Open"
  | "Closing"
  | "Opening"
  | "Error";

const STREAM_SOCKET_URL =
  "wss://jo7rl4zqh1.execute-api.us-east-2.amazonaws.com/dev";

type MessageCB = (message: any) => void;

interface State {
  messages: string[];
  cbs: Record<string, Array<MessageCB>>;
}

const addMessage = actionCreator("add_message")<string>();
const removeNMessages = actionCreator("remove_n_messages")<number>();
const addCb = actionCreator("add_cb")<{ key: string; cb: MessageCB }>();
const removeCb = actionCreator("remove_cb")<{ key: string; cb: MessageCB }>();

const reducer = (
  state: State,
  action:
    | ReturnType<typeof addMessage>
    | ReturnType<typeof removeNMessages>
    | ReturnType<typeof addCb>
    | ReturnType<typeof removeCb>,
) => {
  switch (action.type) {
    case "add_message":
      return { ...state, messages: [...state.messages, action.payload] };
    case "remove_n_messages":
      return { ...state, messages: state.messages.slice(action.payload) };
    case "add_cb":
      const existingCbs =
        action.payload.key in state.cbs ? state.cbs[action.payload.key] : [];
      return {
        ...state,
        cbs: {
          ...state.cbs,
          [action.payload.key]: [...existingCbs, action.payload.cb],
        },
      };
    case "remove_cb":
      if (action.payload.key in state.cbs === false) return state;
      const cbs = state.cbs[action.payload.key];
      const idx = cbs.indexOf(action.payload.cb);
      if (idx === -1) return state;
      return {
        ...state,
        cbs: {
          ...state.cbs,
          [action.payload.key]: [...cbs.slice(0, idx), ...cbs.slice(idx + 1)],
        },
      };
    default:
      return state;
  }
};

const getSubType = (assetType: AssetType): SUB_TYPE => {
  switch (assetType) {
    case AssetType.NFT:
      return SUB_TYPE.NFT_FLOOR_PRICE;
    case AssetType.TOKEN:
      return SUB_TYPE.TOKEN_SWAP_PRICE;
    default:
      throw new Error(`Unsupported asset type ${assetType}`);
  }
};

const getWSServerGranularity = (
  granularity: Granularity,
): WSServerGranularities => {
  switch (granularity) {
    case Granularity.OneMin:
      return WSServerGranularities.ONE_MIN;
    case Granularity.FiveMin:
      return WSServerGranularities.FIVE_MIN;
    case Granularity.Hour:
      return WSServerGranularities.ONE_HOUR;
    case Granularity.Day:
      return WSServerGranularities.ONE_DAY;
    default:
      throw new Error(
        `Missing WS Server granularity mapping for resolution ${granularity}`,
      );
  }
};

export interface WSSubscriptionContextProps {
  status: WebSocketStatus;
  subscribe?: (
    assetType: AssetType,
    granularity: Granularity,
    identifier: string,
    cb: MessageCB,
  ) => void;
  unsubscribe?: (
    assetType: AssetType,
    granularity: Granularity,
    identifier: string,
    cb: MessageCB,
  ) => void;
}
export const WSSubscriptionContext = createContext<WSSubscriptionContextProps>({
  status: "None",
});

const toKey = (
  assetType: SUB_TYPE,
  granularity: WSServerGranularities,
  identifier: string,
) => `${assetType}-${granularity}-${identifier}`;
const isBrowser = typeof window !== "undefined";
export const WSSubscriptionProvider = ({ children }: PropsWithChildren) => {
  const [status, setStatus] = useState<WebSocketStatus>("None");
  const [state, dispatch] = useReducer(reducer, { messages: [], cbs: {} });
  const socket = useMemo(() => {
    if (!isBrowser) return;
    const ws = new WebSocket(STREAM_SOCKET_URL);
    setStatus("Opening");
    ws.addEventListener("open", () => {
      setStatus("Open");
    });
    ws.addEventListener("close", () => {
      setStatus("Closed");
    });
    ws.addEventListener("error", () => {
      setStatus("Error");
    });
    ws.addEventListener("message", (message: MessageEvent<string>) => {
      dispatch(addMessage(message.data));
    });
    return ws;
  }, []);

  useEffect(() => {
    if (!state.messages.length) return;
    const length = state.messages.length;
    for (let i = 0; i < length; i++) {
      const message = JSON.parse(state.messages[i]);
      const key = toKey(message.type, message.granularity, message.identifier);
      if (key in state.cbs === false) continue;
      const cbs = state.cbs[key];
      cbs.forEach((cb) => cb(message));
    }
    dispatch(removeNMessages(length));
  }, [state.messages]);

  const subscribe = useMemo(
    () =>
      (
        assetType: AssetType,
        granularity: Granularity,
        identifier: string,
        cb: MessageCB,
      ) => {
        const payload = {
          action: "subscribe",
          subType: getSubType(assetType),
          granularity: getWSServerGranularity(granularity),
          identifier,
        };
        const key = toKey(payload.subType, payload.granularity, identifier);
        const alreadySubscribed = key in state.cbs && state.cbs[key].length;
        dispatch(addCb({ key, cb }));
        if (!alreadySubscribed) socket?.send(JSON.stringify(payload));
      },
    [dispatch, socket, state.cbs],
  );

  const unsubscribe = useMemo(
    () =>
      (
        assetType: AssetType,
        granularity: Granularity,
        identifier: string,
        cb: MessageCB,
      ) => {
        const payload = {
          action: "unsubscribe",
          subType: getSubType(assetType),
          granularity: getWSServerGranularity(granularity),
          identifier,
        };
        const key = toKey(payload.subType, payload.granularity, identifier);
        const isLast = key in state.cbs && state.cbs[key].length === 1;
        dispatch(removeCb({ key, cb }));
        if (isLast && socket?.readyState === WebSocket.OPEN)
          socket?.send(JSON.stringify(payload));
      },
    [dispatch, socket, state.cbs],
  );

  return (
    <WSSubscriptionContext.Provider
      value={{
        status,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </WSSubscriptionContext.Provider>
  );
};
