type IfProps = {
  condition: boolean;
  children: any;
};

const If = ({ condition, children }: IfProps) => {
  return <>{condition ? children : null}</>;
};

export default If;
