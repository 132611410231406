import { LeaderboardTimeRanges, TokenListSort } from "model/aggregations";
import {
  ListTokensRequest,
  ListTokensResponse,
} from "model/aggregations/defiTokens";
import { SortDirection } from "model/aggregations/nfts/constants";
import useClient from "./useClient";
import useSWR from "swr";

type useTokenLeaderboardReturnState = {
  tokenLeaderboard: ListTokensResponse;
  isLoading: boolean;
  refetch: () => Promise<ListTokensResponse | undefined>;
};

export type useTokenLeaderboardType = {
  // direction of sort, if applied
  direction?: SortDirection;
  // Sort to apply
  sort?: TokenListSort;
  // Sets the number of results to fetch AKA page size
  pageSize?: number;
  // offset === starting point of fetched results
  offset?: number;
  // Time range filter to apply
  timeRangeFilter?: LeaderboardTimeRanges;
  // tokens retrieved from a pre-fetch method like getServerSideProps() or getStaticProps()
  initialTokens?: ListTokensResponse;
  // filtering by a single token name;
  nameFilter?: string;
  // Causes the fetch to re-execute on the specified interval.
  refreshIntervalSeconds?: number;
  // array of token mint addresses to fetch
  arrayOfMints?: string[];

  shouldNotFetch?: boolean;
};

export const DEFAULT_TOKEN_LEADERBOARD_PAGE_SIZE = 100;
const TWENTY_FOUR_HOURS_IN_S = 60 * 60 * 24;

const useTokenLeaderboard = ({
  pageSize,
  offset,
  direction,
  sort,
  timeRangeFilter,
  initialTokens,
  nameFilter,
  refreshIntervalSeconds,
  arrayOfMints,
  shouldNotFetch,
}: useTokenLeaderboardType): useTokenLeaderboardReturnState => {
  const defiTokenClient = useClient("defiTokens");

  const { data, isLoading, mutate } = useSWR(
    shouldNotFetch
      ? null
      : [
          "/listTokensV3",
          direction,
          sort,
          pageSize,
          timeRangeFilter,
          nameFilter,
          offset,
          arrayOfMints,
        ],
    async () => {
      const params: ListTokensRequest = {
        timeRangeFilter: timeRangeFilter ?? LeaderboardTimeRanges.DAY,
        pageSize: pageSize ?? DEFAULT_TOKEN_LEADERBOARD_PAGE_SIZE,
        offset: offset,
        sort: sort,
        sortDirection: direction,
        arrayOfMints:
          arrayOfMints && !arrayOfMints.length
            ? undefined
            : JSON.stringify(arrayOfMints),
        nameFilter,
      };
      const response = await defiTokenClient.listTokensV3({ params });

      return response;
    },
    {
      refreshInterval:
        (refreshIntervalSeconds || TWENTY_FOUR_HOURS_IN_S) * 1000,
      fallbackData: initialTokens!,
      keepPreviousData: true,
    },
  );

  return {
    isLoading,
    tokenLeaderboard: data ?? { tokens: [], totalRowCount: 0 },
    refetch: mutate,
  };
};

export default useTokenLeaderboard;
