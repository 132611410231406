export interface Action<TType extends string, TPayload> {
  type: TType;
  payload: TPayload;
}

export const action = <TType extends string, TPayload>(
  type: TType,
  payload: TPayload,
): Action<TType, TPayload> => ({ type, payload });
export const actionCreator =
  <TType extends string>(type: TType) =>
  <TPayload>() =>
  (payload: TPayload): Action<TType, TPayload> => ({
    type,
    payload,
  });
