import type {
  QueryCommandOutput,
  ScanCommandOutput,
} from "@aws-sdk/client-dynamodb";

export type DocumentQueryReturn = {
  error: any;
  data: QueryCommandOutput;
};

export type DocumentScanReturn = {
  error: any;
  data: ScanCommandOutput;
};

export enum ChannelColors {
  blue = "blue",
  green = "green",
}

export type DynamoFlinkDeploymentConfig = {
  control: string;
  color: ChannelColors;
};

// Eventually we will have same table names with different databases (hopefully)
// But for now this will be harcoded as enums can't use process.env
export enum DynamoTables {
  FreshTime = "prod-fresh_data_series",
  ProdFlinkDeployment = "prod-FlinkDeployment",
  StepFunctionLastBlockTime = "prod-StepFunctionLastBlockTime",
}

/**
 * A list of tables that should be prefixed with an environment prefix before querying.
 * Environment prefixes are controlled via process.ENV vars.
 */
export enum MultiEnvironmentDynamoTables {
  UserMeta = "UserMeta",
  WebVisibilityFlags = "WebVisibilityFlags",
  TVCharts = "TVCharts",
  JupiterSwaps = "JupiterSwaps",
  Configuration = "Configuration",
  DiscordWalletMapping = "DiscordWalletMapping",
}

export enum PARAMS {
  USER_ID = ":userId",
}
