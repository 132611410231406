import {
  DeleteItemCommand,
  DeleteItemCommandInput,
  DeleteItemCommandOutput,
  DynamoDBClient,
  GetItemCommand,
  GetItemCommandInput,
  GetItemCommandOutput,
  PutItemCommand,
  PutItemCommandInput,
  PutItemCommandOutput,
  QueryCommand,
  QueryCommandInput,
  QueryCommandOutput,
  ScanCommand,
  ScanCommandInput,
  ScanCommandOutput,
  UpdateItemCommand,
  UpdateItemCommandInput,
  UpdateItemCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { MultiEnvironmentDynamoTables } from "./types";
export * from "./types";
import { env } from "process";

const ACCESS_KEY_ID = env.DEV_DYNAMO_ACCESS_KEY as string;
const SECRET_KEY = env.DEV_SECRET_SECRET_KEY as string;
const DYNAMO_ENV_PREFIX = env.DYNAMO_ENV_PREFIX as string;
const region = "us-east-2";

export const getEnvPrefixedTableName = (
  tableName: MultiEnvironmentDynamoTables,
) => `${DYNAMO_ENV_PREFIX}-${tableName}`;

export class DynamoDB {
  docClient;
  constructor() {
    this.docClient = new DynamoDBClient({
      region,
      credentials: {
        accessKeyId: ACCESS_KEY_ID,
        secretAccessKey: SECRET_KEY,
      },
    });
  }

  /**
   * Helper function to resolve document queries in async FASHION
   * @param params
   * @returns
   */
  async queryDocument(params: QueryCommandInput): Promise<QueryCommandOutput> {
    return await this.docClient.send(new QueryCommand(params));
  }

  private async scanDocument(
    params: ScanCommandInput,
  ): Promise<ScanCommandOutput> {
    return this.docClient.send(new ScanCommand(params));
  }

  async put(params: PutItemCommandInput): Promise<PutItemCommandOutput> {
    return this.docClient.send(new PutItemCommand(params));
  }

  async updateItem(
    params: UpdateItemCommandInput,
  ): Promise<UpdateItemCommandOutput> {
    return this.docClient.send(new UpdateItemCommand(params));
  }

  async getItem(params: GetItemCommandInput): Promise<GetItemCommandOutput> {
    return this.docClient.send(new GetItemCommand(params));
  }

  async removeItem(
    params: DeleteItemCommandInput,
  ): Promise<DeleteItemCommandOutput> {
    return this.docClient.send(new DeleteItemCommand(params));
  }

  async getTable(TableName: string) {
    return await this.scanDocument({ TableName });
  }

  async getMultiEnvTable(tableName: MultiEnvironmentDynamoTables) {
    const params: ScanCommandInput = {
      TableName: getEnvPrefixedTableName(tableName),
    };
    return await this.scanDocument(params);
  }

  getDevOrProdEnvTableName(tableName: MultiEnvironmentDynamoTables) {
    return getEnvPrefixedTableName(tableName);
  }
}

const DynDB = new DynamoDB();

export default DynDB;
