const SOL_MULTIPLIER = 0.000000001;
export const convertLamportsToSol = (v: string | number) =>
  Number(v) * SOL_MULTIPLIER;

const USD_MULTIPLIER = 1000000;
export const convertUsdToSol = (v: string | number) =>
  Number(v) * USD_MULTIPLIER;

export const BUFFER_FEE_IN_SOLANA = 0.0005;
export const SOLANA_ADDRESS_REGEX = /[1-9A-HJ-NP-Za-km-z]{32,44}/gm;

export const SOLANA_IGNORED_PROGRAM_ADDRESSES = [
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
  "11111111111111111111111111111111",
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL",
  "Sysvar1111111111111111111111111111111111111",
  "Vote111111111111111111111111111111111111111",
];

export const SOLANA_IGNORED_PROGRAM_ADDRESSES_SET = new Set(
  SOLANA_IGNORED_PROGRAM_ADDRESSES,
);
