import { Item } from "@radix-ui/react-navigation-menu";

interface NavItemProps {
  children: React.ReactNode;
  active?: boolean;
}

/**
 *  Wrapper around Radix-UI NavigationMenu.Item. This should only
 * be used within a Radix navigation menu.
 */
export default function NavLink({ active = false, children }: NavItemProps) {
  const activeClass = "border-solid border-0 border-b-4 border-b-hmio-blue";
  return (
    <Item className={`group ${active ? activeClass : ""}`}>{children}</Item>
  );
}
