import { useRef } from "react";

/**
 * https://typeofnan.dev/how-to-prevent-useeffect-from-running-on-mount-in-react/
 *
 * mountRef: tracks if the component has mounted or not.
 * it starts off as false, once the effect runs we set the ref to true.
 */
const useMounted = () => {
  let mountRef = useRef(false);
  let isMounted = mountRef.current;

  return {
    isMounted,
    mountRef,
  };
};

export default useMounted;
