export enum SolanaDefiTokenIdsToNames {
  "11111111111111111111111111111111" = "sol",
  "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt" = "apt",
  "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx" = "atlas",
  "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP" = "aurory",
  "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E" = "btc",
  "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp" = "fida",
  "Fros6mfar61F2b64NuP5G1VzmtqpqU4HQ16V1EbbYfP3" = "josh",
  "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC" = "larix",
  "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac" = "mango",
  "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey" = "mnde",
  "7bxEbXaKrxt3FnxmCTNFmoZsz85SDUgE79wy7ob2fbiC" = "monkedao",
  "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE" = "orca",
  "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M" = "oxy",
  "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk" = "polis",
  "PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y" = "port",
  "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R" = "ray",
  "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1" = "sbr",
  "METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL" = "slc",
  "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp" = "slnd",
  "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm" = "socn",
  "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk" = "soeth",
  "BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW" = "sousdc",
  "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag" = "sunny",
  "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs" = "tulip",
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v" = "usdc",
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB" = "usdt",
  "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE" = "woof",
  "8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr" = "aausdc",
  "8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69" = "abusdc",
  "DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui" = "acusdc",
  "DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9" = "aeusdc",
  "Grk6b4UMRWkgyq4Y6S1BnNRF4hRgtnMFp7Sorkv6Ez4u" = "afusdc",
  "12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG" = "akro",
  "eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca" = "apusdc",
  "45vwTZSDFBiqCMRdtK4xiLCHEov8LJRW8GwnofG8HYyH" = "bear",
  "Gqu3TFmJXfnfSX84kqbZ5u9JjSBVoesaHjfTsaPjRSnZ" = "cbtc",
  "AppJPZka33cu4DyUenFe9Dc1ZmZ3oQju6mBn9k37bNAa" = "ceth",
  "A38TjtcYrfutXT6nfRxhqwoGiXyzwJsGPmekoZYYmfgP" = "cftt",
  "BsWLxf6hRJnyytKR52kKBiz7qU7BB3SH77mrBxNnYU1G" = "cmer",
  "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh" = "cope",
  "2d95ZC8L5XP6xCnaKx8D5U5eX6rKbboBBAwuBLxaFmmJ" = "cray",
  "Bpm2aBL57uqVhgxutfRVrbtnjDpZLV8PZrRrytV1LgeT" = "csbr",
  "5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV" = "csol",
  "4CxGuD2NMr6zM8f18gr6kRhgd748pnmkAhkY1YJtkup1" = "csrm",
  "993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk" = "cusdc",
  "BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8" = "cusdt",
  "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z" = "dxl",
  "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz" = "gene",
  "Br4WZq7N5gFK3WX8Ls1U15ePnQ1DnSKwx5k62X51LeLB" = "kcstsol",
  "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6" = "kin",
  "2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn" = "kuro",
  "2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX" = "link",
  "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K" = "mer",
  "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS" = "pai",
  "CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA" = "people",
  "PSoLjQL5Cd4mwC2p2qeSyHsTtSrifCSmNCUYZK75RFS" = "pumpsol",
  "FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58" = "pusdc",
  "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU" = "samo",
  "2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7" = "slb",
  "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr" = "slrs",
  "8cTNUtcV2ueC3royJ642uRnvTxorJAWLZc58gxAo7y56" = "snx",
  "BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4" = "sousdt",
  "3kT3oYuS1rCfhmqfgy6EKcbZdaJimaVEjoy25QiuEaoj" = "stsolwstethaquafarm",
  "5WXyG6zL1HmESPCSHHKBtqLuRPZCNgd9mTB25op87FkU" = "stsolwstethdoubledip",
  "GtFtWCcLYtWQT8NLRwEfUqc9sgVnq4SbuSnMCpwcutNk" = "tuslrs",
  "EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7" = "ups",
  "So11111111111111111111111111111111111111112" = "wsol",
  "7NFin546WNvWkhtfftfY77z8C1TrxLbUcKmw5TpHGGtC" = "wuniusdceth_v1",
  "yMstKuGEW6v34MB4d3wBd4BTEsHhexLzZca6cCg2DPy" = "ystsol2pool",
  "stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV" = "stsolsolsaber",
  "4q19vhpG6y4ZeMPLQiUNNaJStb8XivCFQy4m4mdnVnQZ" = "stsolsol",
  "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj" = "stsol",
  "F3rWkGAtdjWcU1rr16Wq4YPTgFdsyb1oS1xdy5tr9K1r" = "slrsusdcdoubledip",
  "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So" = "msol",
  "4JEaBv49a4KdSrMduKZS3PcBCcPmPEmaY3uP7kXv6cj6" = "$ass",
  "HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN" = "$honey",
  "674PmuiDtgKx3uKuJ1B16f9m5L84eFvNwj3xDMvHcbo7" = "$wood",
  "6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M" = "aap",
  "AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca" = "aod",
  "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg" = "apex",
  "ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond" = "asol",
  "3LKZU3iQx9KM94S4uYRdYwAHTm6odDyzGQqTBNj7J27z" = "atluna",
  "BK2YNwsExxnjSUgdAzdvLV2FrthcNGGWTxDBvfBULCjG" = "aussdashusdc",
  "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa" = "bnb",
  "CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS" = "boku",
  "3rj25VfGX89DXMBTjghcpszibzx88NidMbUy2oHHKgSs" = "bread",
  "92bcERNtUmuaJ6mwLSxYHZYSph37jdKxRdoYNxpcYNPp" = "btcdashmsol",
  "SLPbsNrLHv8xG4cTc4R5Ci8kB9wUPs6yn6f7cKosoxs" = "btcdashrenbtc",
  "DzpLz78wuwyFsQToin8iDv6YK6aBEymRqQq82swiFh7r" = "btcovermsol",
  "6uA1ADUJbvwYJZpzUn9z9LuyKoRVngBKcQTKdXsSivA8" = "btcovermsoldoubledip",
  "kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa" = "butter",
  "5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB" = "cato",
  "3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc" = "cheems",
  "6UA3yn28XecAHLTwoCtjfzy3WcyQj1x13bxnH8urUiKt" = "fcmsol",
  "5VmdHqvRMbXivuC34w4Hux9zb1y9moiBEQmXDrTR1kV" = "fcscnsol",
  "DsBuznXRTmzvEdb36Dx3aVLVo1XmH7r1PRZUFugLPTFv" = "fidadashray",
  "FJ9Q9ojA7vdf5rFbcTc6dd7D3nLpwSxdtFSE8cwfuvqt" = "fidaoversol",
  "3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw" = "floof",
  "5vUBtmmHjSfpY1h24XhzEjRKjDyK5jNL9gT2BfM3wcnb" = "gfida",
  "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA" = "grape",
  "97q89hnoKwqcynvwXcj83YqfqUBuCm4A8f2zHeV6bfZg" = "grapeoverusdc",
  "BECBejLA8KGEzXKvfCwyuFFfeiyhBYxULF4MhVdyduQ5" = "honey",
  "E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA" = "ino",
  "JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz" = "jet",
  "AyDGcnCpYLRCZygMCLjfioEZxgzH1FbySNLrEoJR27yX" = "jpr",
  "Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi" = "jungle",
  "H1HuyAvxPcbT6nGW8o4jENjZgDdNmq6DJSXwTbGHwNaQ" = "jupiter",
  "E2eevzN2TBWAL1LiALAfEYFLRBzSDLugLjiNuZg9ehwT" = "kcmsol",
  "2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG" = "kekw",
  "FJJT7yUJM9X9SHpkVr4wLgyfJ3vtVLoReUqTsCPWzof2" = "kekwdashusdc",
  "HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p" = "ldo",
  "L1edE4UVsmeqdyK5bWpxzhozxw3jgnfcuimVj1SnyCf" = "lido",
  "F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W" = "luna",
  "Do9mNqryUMyuCLM2avYcimiMtbND7Gyce4z2MGp4VHQq" = "lzrd",
  "Gnhy3boBT4MA8TTjGip5ND2uNsceh1Wgeaw1rYJo51ZY" = "mapspool",
  "Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A" = "mbs",
  "HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8" = "meow",
  "2FMpVEhvxiFxhfideFUMNxCoUZK3TfhezzajoHGTQKP2" = "mndeovermsol",
  "ER8Xa8YxJLC3CFJgdAxJs46Rdhb7B3MjgbPZsVg1aAFV" = "molamon",
  "FmQN1sQDeD9DF7aQvmJA9zZ8hicJYxUzzCDSnV8tfUtY" = "noot",
  "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A" = "oogi",
  "3Duk5b6fLztPmS4ryV48FM1Q9WXUSMwz9jehAT4UtqpE" = "orcaovermsol",
  "876yhw4J4GHyynNJUtARYEnWGaejhrWC7Hy3DAm1pZxi" = "orcaovermsoldoubledip",
  "9iDWyYZ5VHBCxxmWZogoY3Z6FSbKsX4WFe37c728krdT" = "oxypool",
  "9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX" = "parrotsol",
  "DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun" = "pbtc",
  "BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG" = "pgnt",
  "PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe" = "phoney",
  "Dt1Cuau5m5CSmun8hZstjEh9RszxAmejnq7ZaHNcuXfA" = "pmsol",
  "GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB" = "potato",
  "E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT" = "ppug",
  "PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44" = "prt",
  "G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB" = "puff",
  "4fiysjiegD8yoqHX75YXBvhBzmaUEQhyuAVRX8fGL3F1" = "raypool",
  "E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp" = "rin",
  "5JaZgVn2LuKmRyBMhcfydq6L3dxmZhySeTE7rePYbH3k" = "rlab",
  "9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H" = "sbtcdash9",
  "SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj" = "scnsoldashsol",
  "8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s" = "sdoge",
  "FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt" = "sfttdash9",
  "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y" = "shdw",
  "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K" = "soaleph",
  "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN" = "solape",
  "5ijRoAHVgd5T5CNtK5KDRUBZ7Bffb69nktMj5n6ks6m4" = "soldashmsol",
  "CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG" = "solink",
  "9r1n79TmerAgQJboUT8QvrChX3buZBfuSrBTtYM1cW4h" = "soloverstep",
  "AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy" = "sosushi",
  "3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB" = "soyfi",
  "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT" = "step",
  "3k8BDobgihmk72jVmXYLE168bxxQUhqqyESW4dQVktqC" = "stepdashusdc",
  "6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH" = "stepn",
  "GwrBA1F8rGummDCDd8NY9Eu1cLNuJqbT8WaGxgWpFwGL" = "stepoversol",
  "H2mf9QNdU2Niq6QR7367Ua2trBsvscLyX5bz7R3Pw5sE" = "steth",
  "43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We" = "stnk",
  "9HPn1oREyNA7CEK7B1xwmBmVH6qtQaSfLBXc1JyRsdUE" = "sunnyoverusdc",
  "Bq3N5VVipKZBqvQzz7tnzQGGU4kNdJaq3ZBgQncU7bLU" = "turf",
  "AG1wkgfXJNkS2xWsbDePVsVDsdiZofcm2JpYiMfvPnHg" = "tusk",
  "GQVAdB2mYSNG1amewdSmTheoFGggy99Hjv6fDjTZrnFo" = "venom",
  "LUNkiLcb2wxcqULmJvMjuM6YQhpFBadG5KZBe7qBpSE" = "wlunav1dashrenluna",
  "ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo" = "wsteth",
  "xAURp5XmAG7772mfkSy6vRAjGK9JofYjc3dmQDWdVDP" = "xaury",
  "Ga2AXHpfAF6mv2ekZwcsJFqu7wB4NV331qNH7fW9Nst8" = "xrp",
  "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G" = "xstep",
  "yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM" = "yprt",
  "yUSDT1nYX9JHLqRCX1RqZyATiRKaSYiMLWLMatr3U2h" = "yusdc",
  "Bh3PCgvfquVbbRe9Q22FgV6qvF9n24VpbtuTUoVQTNRw" = "lifinitySOL",
  "2YTL3rHryiZ3cxrw1AK2X45yJg33RAarZ4e9FziWRtV" = "lifinityUSDC",
  "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs" = "eth",
  "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt" = "srm",
}

export enum SolanaDefiTokenIds {
  apt = "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt",
  atlas = "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
  aurory = "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
  btc = "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
  fida = "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
  josh = "Fros6mfar61F2b64NuP5G1VzmtqpqU4HQ16V1EbbYfP3",
  larix = "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC",
  mango = "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
  mnde = "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
  monkedao = "7bxEbXaKrxt3FnxmCTNFmoZsz85SDUgE79wy7ob2fbiC",
  orca = "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
  oxy = "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M",
  polis = "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
  port = "PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y",
  ray = "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
  sbr = "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
  slc = "METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL",
  slnd = "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
  socn = "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
  soeth = "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk",
  sousdc = "BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW",
  sunny = "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
  tulip = "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
  usdc = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  usdt = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
  woof = "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE",
  aausdc = "8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr",
  abusdc = "8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69",
  acusdc = "DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui",
  aeusdc = "DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9",
  afusdc = "Grk6b4UMRWkgyq4Y6S1BnNRF4hRgtnMFp7Sorkv6Ez4u",
  akro = "12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG",
  apusdc = "eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca",
  bear = "45vwTZSDFBiqCMRdtK4xiLCHEov8LJRW8GwnofG8HYyH",
  cbtc = "Gqu3TFmJXfnfSX84kqbZ5u9JjSBVoesaHjfTsaPjRSnZ",
  ceth = "AppJPZka33cu4DyUenFe9Dc1ZmZ3oQju6mBn9k37bNAa",
  cftt = "A38TjtcYrfutXT6nfRxhqwoGiXyzwJsGPmekoZYYmfgP",
  cmer = "BsWLxf6hRJnyytKR52kKBiz7qU7BB3SH77mrBxNnYU1G",
  cope = "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
  cray = "2d95ZC8L5XP6xCnaKx8D5U5eX6rKbboBBAwuBLxaFmmJ",
  csbr = "Bpm2aBL57uqVhgxutfRVrbtnjDpZLV8PZrRrytV1LgeT",
  csol = "5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV",
  csrm = "4CxGuD2NMr6zM8f18gr6kRhgd748pnmkAhkY1YJtkup1",
  cusdc = "993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk",
  cusdt = "BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8",
  dxl = "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
  gene = "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
  kcstsol = "Br4WZq7N5gFK3WX8Ls1U15ePnQ1DnSKwx5k62X51LeLB",
  kin = "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
  kuro = "2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn",
  link = "2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX",
  mer = "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K",
  pai = "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS",
  people = "CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA",
  pumpsol = "PSoLjQL5Cd4mwC2p2qeSyHsTtSrifCSmNCUYZK75RFS",
  pusdc = "FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58",
  samo = "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
  slb = "2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7",
  slrs = "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
  snx = "8cTNUtcV2ueC3royJ642uRnvTxorJAWLZc58gxAo7y56",
  sousdt = "BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4",
  stsolwstethaquafarm = "3kT3oYuS1rCfhmqfgy6EKcbZdaJimaVEjoy25QiuEaoj",
  stsolwstethdoubledip = "5WXyG6zL1HmESPCSHHKBtqLuRPZCNgd9mTB25op87FkU",
  tuslrs = "GtFtWCcLYtWQT8NLRwEfUqc9sgVnq4SbuSnMCpwcutNk",
  ups = "EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7",
  wsol = "So11111111111111111111111111111111111111112",
  wuniusdceth_v1 = "7NFin546WNvWkhtfftfY77z8C1TrxLbUcKmw5TpHGGtC",
  ystsol2pool = "yMstKuGEW6v34MB4d3wBd4BTEsHhexLzZca6cCg2DPy",
  stsolsolsaber = "stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV",
  stsolsol = "4q19vhpG6y4ZeMPLQiUNNaJStb8XivCFQy4m4mdnVnQZ",
  stsol = "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj",
  slrsusdcdoubledip = "F3rWkGAtdjWcU1rr16Wq4YPTgFdsyb1oS1xdy5tr9K1r",
  msol = "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
  $ass = "4JEaBv49a4KdSrMduKZS3PcBCcPmPEmaY3uP7kXv6cj6",
  $honey = "HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN",
  $wood = "674PmuiDtgKx3uKuJ1B16f9m5L84eFvNwj3xDMvHcbo7",
  aap = "6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M",
  aod = "AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca",
  apex = "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg",
  asol = "ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond",
  atluna = "3LKZU3iQx9KM94S4uYRdYwAHTm6odDyzGQqTBNj7J27z",
  aussdashusdc = "BK2YNwsExxnjSUgdAzdvLV2FrthcNGGWTxDBvfBULCjG",
  bnb = "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
  boku = "CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS",
  bread = "3rj25VfGX89DXMBTjghcpszibzx88NidMbUy2oHHKgSs",
  btcdashmsol = "92bcERNtUmuaJ6mwLSxYHZYSph37jdKxRdoYNxpcYNPp",
  btcdashrenbtc = "SLPbsNrLHv8xG4cTc4R5Ci8kB9wUPs6yn6f7cKosoxs",
  btcovermsol = "DzpLz78wuwyFsQToin8iDv6YK6aBEymRqQq82swiFh7r",
  btcovermsoldoubledip = "6uA1ADUJbvwYJZpzUn9z9LuyKoRVngBKcQTKdXsSivA8",
  butter = "kti1UbBFcCZbfccmGE57W9f6Byy5EY9YLLdsecs3UHa",
  cato = "5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB",
  cheems = "3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc",
  fcmsol = "6UA3yn28XecAHLTwoCtjfzy3WcyQj1x13bxnH8urUiKt",
  fcscnsol = "5VmdHqvRMbXivuC34w4Hux9zb1y9moiBEQmXDrTR1kV",
  fidadashray = "DsBuznXRTmzvEdb36Dx3aVLVo1XmH7r1PRZUFugLPTFv",
  fidaoversol = "FJ9Q9ojA7vdf5rFbcTc6dd7D3nLpwSxdtFSE8cwfuvqt",
  floof = "3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw",
  gfida = "5vUBtmmHjSfpY1h24XhzEjRKjDyK5jNL9gT2BfM3wcnb",
  grape = "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA",
  grapeoverusdc = "97q89hnoKwqcynvwXcj83YqfqUBuCm4A8f2zHeV6bfZg",
  honey = "BECBejLA8KGEzXKvfCwyuFFfeiyhBYxULF4MhVdyduQ5",
  ino = "E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA",
  jet = "JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz",
  jpr = "AyDGcnCpYLRCZygMCLjfioEZxgzH1FbySNLrEoJR27yX",
  jungle = "Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi",
  jupiter = "H1HuyAvxPcbT6nGW8o4jENjZgDdNmq6DJSXwTbGHwNaQ",
  kcmsol = "E2eevzN2TBWAL1LiALAfEYFLRBzSDLugLjiNuZg9ehwT",
  kekw = "2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG",
  kekwdashusdc = "FJJT7yUJM9X9SHpkVr4wLgyfJ3vtVLoReUqTsCPWzof2",
  ldo = "HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p",
  lido = "L1edE4UVsmeqdyK5bWpxzhozxw3jgnfcuimVj1SnyCf",
  luna = "F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W",
  lzrd = "Do9mNqryUMyuCLM2avYcimiMtbND7Gyce4z2MGp4VHQq",
  mapspool = "Gnhy3boBT4MA8TTjGip5ND2uNsceh1Wgeaw1rYJo51ZY",
  mbs = "Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A",
  meow = "HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8",
  mndeovermsol = "2FMpVEhvxiFxhfideFUMNxCoUZK3TfhezzajoHGTQKP2",
  mndeovermsoldoubledip = "2FMpVEhvxiFxhfideFUMNxCoUZK3TfhezzajoHGTQKP2",
  molamon = "ER8Xa8YxJLC3CFJgdAxJs46Rdhb7B3MjgbPZsVg1aAFV",
  noot = "FmQN1sQDeD9DF7aQvmJA9zZ8hicJYxUzzCDSnV8tfUtY",
  oogi = "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A",
  orcaovermsol = "3Duk5b6fLztPmS4ryV48FM1Q9WXUSMwz9jehAT4UtqpE",
  orcaovermsoldoubledip = "876yhw4J4GHyynNJUtARYEnWGaejhrWC7Hy3DAm1pZxi",
  oxypool = "9iDWyYZ5VHBCxxmWZogoY3Z6FSbKsX4WFe37c728krdT",
  parrotsol = "9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX",
  pbtc = "DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun",
  pgnt = "BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG",
  phoney = "PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe",
  pmsol = "Dt1Cuau5m5CSmun8hZstjEh9RszxAmejnq7ZaHNcuXfA",
  potato = "GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB",
  ppug = "E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT",
  prt = "PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44",
  puff = "G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB",
  raypool = "4fiysjiegD8yoqHX75YXBvhBzmaUEQhyuAVRX8fGL3F1",
  rin = "E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp",
  rlab = "5JaZgVn2LuKmRyBMhcfydq6L3dxmZhySeTE7rePYbH3k",
  sbtcdash9 = "9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H",
  scnsol = "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
  scnsoldashsol = "SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj",
  sdoge = "8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s",
  sfttdash9 = "FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt",
  shdw = "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
  soaleph = "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K",
  solape = "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN",
  soldashmsol = "5ijRoAHVgd5T5CNtK5KDRUBZ7Bffb69nktMj5n6ks6m4",
  solink = "CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG",
  soloverstep = "9r1n79TmerAgQJboUT8QvrChX3buZBfuSrBTtYM1cW4h",
  sosushi = "AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy",
  soyfi = "3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB",
  step = "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
  stepdashusdc = "3k8BDobgihmk72jVmXYLE168bxxQUhqqyESW4dQVktqC",
  stepn = "6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH",
  stepoversol = "GwrBA1F8rGummDCDd8NY9Eu1cLNuJqbT8WaGxgWpFwGL",
  steth = "H2mf9QNdU2Niq6QR7367Ua2trBsvscLyX5bz7R3Pw5sE",
  stnk = "43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We",
  sunnyoverusdc = "9HPn1oREyNA7CEK7B1xwmBmVH6qtQaSfLBXc1JyRsdUE",
  turf = "Bq3N5VVipKZBqvQzz7tnzQGGU4kNdJaq3ZBgQncU7bLU",
  tusk = "AG1wkgfXJNkS2xWsbDePVsVDsdiZofcm2JpYiMfvPnHg",
  venom = "GQVAdB2mYSNG1amewdSmTheoFGggy99Hjv6fDjTZrnFo",
  wlunav1dashrenluna = "LUNkiLcb2wxcqULmJvMjuM6YQhpFBadG5KZBe7qBpSE",
  wsteth = "ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo",
  xaury = "xAURp5XmAG7772mfkSy6vRAjGK9JofYjc3dmQDWdVDP",
  xrp = "Ga2AXHpfAF6mv2ekZwcsJFqu7wB4NV331qNH7fW9Nst8",
  xstep = "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G",
  yprt = "yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM",
  yusdc = "yUSDT1nYX9JHLqRCX1RqZyATiRKaSYiMLWLMatr3U2h",
  lifinitySOL = "Bh3PCgvfquVbbRe9Q22FgV6qvF9n24VpbtuTUoVQTNRw",
  lifinityUSDC = "2YTL3rHryiZ3cxrw1AK2X45yJg33RAarZ4e9FziWRtV",
  eth = "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
  srm = "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
}

export interface TokenProtocol {
  tokenId?: string;
  protocolId?: string;
}

export function getTokenAndProtocol(
  tokenFilter?: string,
  protocolFilter?: string,
): TokenProtocol {
  const tokenAndProtocol: TokenProtocol = {
    protocolId:
      protocolFilter === "solana"
        ? "11111111111111111111111111111111"
        : protocolFilter || "",
  };

  tokenAndProtocol.tokenId = tokenFilter;

  return tokenAndProtocol;
}
