import DynDB, { DynamoTables } from "db/Dynamo";
import { FormatDataOptions } from ".";
import { logger } from "..";
import {
  BasicAggregationRow,
  Granularities,
  HeadlineTimeRanges,
  LeaderboardTimeRanges,
  TimeRanges,
} from "../../../model/aggregations";
import { Granularity } from "../../../model/aggregations/nfts";

export interface TimeRange {
  startSeconds: number;
  endSeconds: number;
  asOf: Date;
  interval: number;
  intervalTime: string;
}

export enum DateTrunc {
  day = "day",
  week = "week",
}

/**
 * A helper to round seconds to a specified minute interval to reduce variability in requests to the backend. This helps cache.
 * This effectively causes a staleness in the data requested up to minuteMultiple minutes.
 *
 * @param seconds the timestamp in seconds provided
 * @param minuteMultiple the multiple of minutes to round to
 * @returns the seconds rounded down to that multiple of minutes
 *
 * @example
 *
 * roundToMinuteMultple(65, 5) === 60 // rounds down to a multiple of 5 minutes
 */
export function roundToMinuteMultiple(
  seconds: number,
  minuteMultiple: number,
): number {
  const minutes = seconds / 60;
  const minutesRoundedToMinuteMultiple =
    Math.floor(minutes / minuteMultiple) * minuteMultiple;
  const resultSeconds = minutesRoundedToMinuteMultiple * 60;

  return resultSeconds;
}

export const TIME_RANGE_FILTER_NAME = "timeRangeFilter" as const;

export const BEGINNING_OF_SOLANA_TIME = 1608102000000 as const; // Dec 16 2020

/**
 * Converts a specified time range type and end to a detailed range.
 *
 * @param timeRangeFilter the type of time range
 * @param endSeconds the end of the range
 * @returns a set of time range infos. Interval is in minutes.
 */
export function getTimeRange(
  timeRangeFilter: string,
  endSeconds: number,
  allInclusive: boolean = false,
): TimeRange {
  const end = new Date(endSeconds);
  let start = new Date(end.valueOf());
  if (allInclusive) start.setHours(0, 0, 0, 0);

  let interval = 30,
    intervalTime = "30 minutes";

  switch (timeRangeFilter) {
    case TimeRanges.ALL:
      start = new Date(BEGINNING_OF_SOLANA_TIME);
      interval = 60 * 24;
      intervalTime = "24 hours";
      break;
    case TimeRanges.DAY:
      start.setDate(start.getDate() - 1);
      interval = 5;
      intervalTime = "5 minutes";
      break;
    case TimeRanges.WEEK:
      start.setDate(start.getDate() - 7);
      interval = 5;
      intervalTime = "5 minutes";
      break;
    case TimeRanges.MONTH:
    case TimeRanges.TWO_MONTH:
      const monthDelta = Number(timeRangeFilter.split("-")[0]);
      start.setMonth(start.getMonth() - monthDelta);
      interval = 60 * 6;
      intervalTime = "6 hours";
      break;
    default:
      start.setDate(start.getDate() - 1);
      interval = 30;
      intervalTime = "30 minutes";
  }

  return {
    startSeconds: allInclusive
      ? Math.floor(start.valueOf() / 1000)
      : roundToMinuteMultiple(Math.floor(start.valueOf() / 1000), 5),
    endSeconds: allInclusive
      ? Math.floor(end.valueOf() / 1000)
      : roundToMinuteMultiple(Math.floor(end.valueOf() / 1000), 5),
    interval,
    intervalTime,
    asOf: new Date(Math.floor(end.valueOf() / 1000) * 1000),
  };
}

const supportedTimeRanges = [
  TimeRanges.DAY,
  TimeRanges.MONTH,
  TimeRanges.WEEK,
  TimeRanges.MONTH,
  TimeRanges.TWO_MONTH,
  TimeRanges.ALL,
];

/**
 * Tests a provided range to see if it is supported in our ranges.
 *
 * @param range the range input to test
 * @returns whether the provided range is a supported range value
 */
export function isValidTimeRange(range: string | undefined): boolean {
  return supportedTimeRanges.includes(range as TimeRanges);
}

/**
 * Tests if its a valid nft timeline range
 *
 * @param timeRangeFilter
 */
export function isValidNftHeadlineTimeRange(timeRangeFilter: string): boolean {
  // @ts-ignore
  return Object.values(HeadlineTimeRanges).includes(timeRangeFilter);
}

export function isValidLeaderboardTimeRange(timeRangeFilter: string): boolean {
  // @ts-ignore - Argument of type 'string' is not assignable to parameter of type 'LeaderboardTimeRanges'.
  // Allows us to pass a random timeRangeFilter string and check it by the list of valid Leaderboard TimeRanges
  return Object.values(LeaderboardTimeRanges).includes(timeRangeFilter);
}

export function formatToSupportedTimeRanges(timeRangeFilter: string): string {
  if (isValidNftHeadlineTimeRange(timeRangeFilter)) {
    switch (timeRangeFilter) {
      case HeadlineTimeRanges.DAY:
        return TimeRanges.DAY;
      case HeadlineTimeRanges.WEEK:
        return TimeRanges.WEEK;
      default:
        return timeRangeFilter;
    }
  } else if (isValidLeaderboardTimeRange(timeRangeFilter)) {
    switch (timeRangeFilter) {
      case LeaderboardTimeRanges.MINUTE_30:
        return TimeRanges.MINUTE_30;
      case LeaderboardTimeRanges.HOUR_1:
        return TimeRanges.MINUTE_60;
      case LeaderboardTimeRanges.HOUR_6:
        return TimeRanges.HOUR_6;
      case LeaderboardTimeRanges.HOUR_12:
        return TimeRanges.HOUR_12;
      case LeaderboardTimeRanges.DAY:
        return TimeRanges.DAY;
      case LeaderboardTimeRanges.WEEK:
        return TimeRanges.WEEK;
      case LeaderboardTimeRanges.MONTH:
        return TimeRanges.MONTH;
      default:
        return timeRangeFilter;
    }
  } else {
    return timeRangeFilter;
  }
}

export function getGranularityForLeaderboardStats(
  timeRangeFilter: string,
): Granularities {
  switch (timeRangeFilter) {
    case TimeRanges.MINUTE_30:
      return Granularities.THIRTY_MIN;
    case TimeRanges.MINUTE_60:
      return Granularities.ONE_HOUR;
    case TimeRanges.HOUR_6:
      return Granularities.SIX_HOUR;
    case TimeRanges.HOUR_12:
      return Granularities.HALF_DAY;
    case TimeRanges.DAY:
      return Granularities.ONE_DAY;
    case TimeRanges.WEEK:
      return Granularities.ONE_WEEK;
    case TimeRanges.MONTH:
      return Granularities.ONE_MONTH;
    default:
      return Granularities.ONE_DAY;
  }
}

export function validateTimeRange(range: string): void {
  if (isValidTimeRange(range)) {
    return;
  } else if (isValidNftHeadlineTimeRange(range)) {
    return;
  } else if (isValidLeaderboardTimeRange(range)) {
    return;
  } else {
    throw new Error(
      `Bad Request: timeRangeFilter must be one of [${Object.values(
        TimeRanges,
      ).join(", ")}], ` + `[${Object.values(HeadlineTimeRanges).join(",")}]`,
    );
  }
}

/**
 * Takes a time range and minumum supported granularity and returns the granularity that should be returned for this range.
 *
 * We need this because we do not want to send hourly granularity beyond a week.
 *
 * @param range The time range enum
 * @param minGranularity The minumum granularity supported
 * @returns
 */
export function getGranularity(
  range: TimeRanges,
  minGranularity?: Granularity,
): Granularity {
  switch (range) {
    case TimeRanges.DAY:
    case TimeRanges.WEEK:
      return minGranularity || Granularity.Hour;
    default:
      return Granularity.Day;
  }
}

export const convertTimeToFiveMinuteTime = (minutes: number): number =>
  Math.floor(minutes / 5);

export function formatData<Data, X, Y>(
  queryData: any[],
  options?: FormatDataOptions<Data, X, Y>,
): BasicAggregationRow<X, Y>[] {
  const { parseX = (d: X) => d, parseY = (d: Y) => d } = options || {};

  return queryData.map((data) => {
    return {
      x: parseX(data),
      y: parseY(data),
    };
  });
}

export function msInMinInterval(intervalOfMinutes: number) {
  return intervalOfMinutes * 60 * 1000;
}

/**
 * Gets most recent data refresh time for our queries
 * @returns {Promise<number>} end_time for queries
 */
export const getEndTime = async (): Promise<number> => {
  const { Items } = await DynDB.getTable(
    DynamoTables.StepFunctionLastBlockTime,
  );

  if (Items?.length) {
    const hourlyFreshtimeItem = Items.filter(
      (item) =>
        "step_fn_name" in item && item?.step_fn_name.S === "hourly_nft_rollup",
    )[0];

    if (hourlyFreshtimeItem) {
      const lastBlocktime = parseInt(
        hourlyFreshtimeItem.last_blocktime.N || "0",
      );
      const latest_time_ms = lastBlocktime * 1000; // converting seconds to milliseconds

      logger.atInfo(
        "FreshTime",
        lastBlocktime.toString(),
        new Date(latest_time_ms),
      );

      return latest_time_ms;
    }
  }

  logger.atError(
    "Freshtime",
    "Failed to get live freshtime. Using clocktime.",
    Date.now().valueOf(),
  );

  return Date.now().valueOf();
};
