import { WSSubscriptionContext } from "components/WSSubscriptionProvider/WSSubscriptionProvider";
import { AssetType } from "model/aggregations";
import { Granularity } from "model/aggregations/nfts";
import { useState, useMemo, useEffect, useContext } from "react";
import type { CandleStick, SubscriptionResult } from "src/types/candlestick.types";
import { useLastNrtBlockIds } from "./useLastNrtBlockIds";
import { usePrevious } from "./usePrevious";

/**
 * Essentially a peek operation on the existing sol socket connection.
 * WARNING -- use this mainly for PRICE. The granularity is randomized intentionally to spread the subscription load.
 * The websocket server right now can't handle everyone subscribing to the same subscription.
 *
 */
export function useCandlestickSubscription(
  identifier: string,
  assetType: AssetType,
  granularity: Granularity,
  fallbackCandle?: CandleStick
): SubscriptionResult {
  const [candlestick, setCandlestick] = useState<CandleStick | undefined>();
  const [incomingCandle, setIncomingCandlestick] = useState<CandleStick | undefined>();
  const { status, subscribe, unsubscribe } = useContext(WSSubscriptionContext);
  const prevIdentifier = usePrevious(identifier);
  const prevAsset = usePrevious(assetType);
  const prevGranularity = usePrevious(granularity);

  useEffect(() => {
    if (!prevAsset && !prevGranularity && !prevIdentifier) return;
    if (prevAsset === assetType && prevIdentifier === identifier && prevGranularity === granularity) return;
    unsubscribe?.(prevAsset!, prevGranularity!, prevIdentifier!, setIncomingCandlestick);
  }, [identifier, assetType, granularity, prevIdentifier, prevAsset, prevGranularity]);

  useEffect(() => {
    if (status !== "Open" || !subscribe) return;
    subscribe(assetType, granularity, identifier, setIncomingCandlestick);
  }, [status, identifier, assetType, granularity]);

  // Manage listeners
  useEffect(() => {
    // Filter out any non-candle messages.
    if (incomingCandle?.startTime) {
      setCandlestick((existingCandle) => existingCandle && existingCandle.lastBlockId > incomingCandle.lastBlockId
          ? existingCandle
          : incomingCandle
      );
    }
  }, [incomingCandle]);


  // Filter out any candles that are older than the latest data the FE would already have.
  // Don't want any old data coming in from the websocket for any reason.
  const lastServerNrtBlockIds = useLastNrtBlockIds({
    identifier,
  });
  const freshCandlestick = useMemo(
    () => {
      return lastServerNrtBlockIds &&
        lastServerNrtBlockIds.latestCandleLastBlockId &&
        candlestick &&
        candlestick.lastBlockId >= lastServerNrtBlockIds.latestCandleLastBlockId
        ? candlestick
        : undefined;
    },
    [candlestick, lastServerNrtBlockIds],
  );

  useEffect(() => {
    return () => {
      unsubscribe?.(assetType, granularity, identifier, setIncomingCandlestick);
    };
  }, []);

  return {
    candlestick: freshCandlestick || fallbackCandle,
  };
}
