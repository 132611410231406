/**
 * Supported Subscription Types
 */
export enum SUB_TYPE {
  NFT_FLOOR_PRICE = "NFT_FLOOR_PRICE",
  TOKEN_SWAP_PRICE = "TOKEN_SWAP_PRICE",
}

/**
 * Supported Granularities
 */
export enum WSServerGranularities {
  ONE_MIN = "ONE_MIN",
  FIVE_MIN = "FIVE_MIN",
  ONE_HOUR = "ONE_HOUR",
  ONE_DAY = "ONE_DAY",
  ONE_WEEK = "ONE_WEEK",
}
/**
 * CandleStick DataType coming from SQS
 */
export type CandleStick = {
  granularity: WSServerGranularities;
  lastBlockId: number;
  startTime: number;
  high: number;
  low: number;
  open: number;
  close: number;
  volume: number;
  type: SUB_TYPE;
  identifier: string;
};

export type SubscriptionResult = {
  candlestick: CandleStick | undefined;
};
