import { AssetType, TimeRanges } from "model/aggregations";
import { Granularity, MarketActivityRequest } from "model/aggregations/nfts";
import { useState, useMemo, useEffect, useRef } from "react";
import { WSOL_ADDRESS } from "services/utils";
import { useCandlestickSubscription } from "src/hooks/useCandlestickSubscription";
import useClient from "src/hooks/useClient";
import { useLastNrtBlockIds } from "src/hooks/useLastNrtBlockIds";
import useTokenLeaderboard from "src/hooks/useTokenLeaderboard";
import SolanaLogo from "src/icons/V2/Utility/Solana.svg";
import { WSServerGranularities, SUB_TYPE } from "src/types/candlestick.types";
import { formatDollarsString, formatSolString } from "src/utils";
import { formatNum } from "src/utils/format/formatNum";
import { convertUsdToSol } from "src/utils/solana/constants/solana";
import { convertFractionToUSDC } from "src/utils/tokens";

const WSOL_NAME = "Wrapped SOL";
const WSOL_IDENTIFIER = "So11111111111111111111111111111111111111112";

export default function DataBar() {
  const aggregations = useClient("aggregations");
  const [swapVolume1D, setSwapVolume1D] = useState<string>("");
  const [nftMarketVolume1D, setNftMarketVolume1D] = useState<string>("");

  const { tokenLeaderboard } = useTokenLeaderboard({
    nameFilter: WSOL_NAME,
    pageSize: 1,
  });
  const lastServerBlockId = useLastNrtBlockIds({ identifier: WSOL_ADDRESS });

  const preloadedCandle = useMemo(() => {
    const isInitialTokenListLoaded =
      tokenLeaderboard.tokens.length !== 0 &&
      tokenLeaderboard.tokens[0].priceUsd !== 0;

    return isInitialTokenListLoaded
      ? {
          // we only care about close candlestick price
          granularity: WSServerGranularities.ONE_MIN,
          close: convertUsdToSol(tokenLeaderboard.tokens[0].priceUsd),
          lastBlockId: lastServerBlockId?.latestCandleLastBlockId || 0,
          startTime: 0,
          high: 0,
          low: 0,
          open: 0,
          volume: 0,
          type: SUB_TYPE.TOKEN_SWAP_PRICE,
          identifier: AssetType.TOKEN,
        }
      : undefined;
  }, [tokenLeaderboard, lastServerBlockId?.latestCandleLastBlockId]);

  const { candlestick } = useCandlestickSubscription(
    WSOL_IDENTIFIER,
    AssetType.TOKEN,
    Granularity.OneMin,
    preloadedCandle,
  );

  // Get swap volume
  useEffect(() => {
    const fetchSwapVolume = async () => {
      const response = await aggregations.defiMarketActivity({});

      setSwapVolume1D(
        formatDollarsString(
          formatNum(response.aggregates.swapVolume, 2, false),
        ),
      );
    };

    fetchSwapVolume();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get market volume
  useEffect(() => {
    const fetchMarketVolume = async () => {
      const params: MarketActivityRequest = {
        timeRange: TimeRanges.DAY,
      };
      const response = await aggregations.nftMarketActivity({ params });

      setNftMarketVolume1D(
        formatSolString(
          formatNum(
            response.activity.reduce((accum, el) => accum + el.volumeSol, 0),
            2,
            false,
          ),
        ),
      );
    };

    fetchMarketVolume();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sticky bottom-0 z-50 w-full h-10 overflow-y-hidden text-sm border-0 border-t border-solid bg-hmio-black border-hmio-black-900">
      <div
        className={`w-full flex items-center md:flex-row mt-2 flex-col animate-live-data-scroll md:animate-none  duration-300 md:translate-y-0`}
      >
        <div className="flex justify-center w-full mb-4 md:w-auto md:mb-0 md:justify-start">
          <div className="flex items-center justify-center flex-1 md:flex-none text-hmio-white">
            <div className="relative w-4 h-4 mr-2 md:ml-10">
              <div className="absolute inline-flex w-2 h-2 rounded-full opacity-75 top-1 left-1 animate-live-data-pulse bg-hmio-blue"></div>
              <div className="absolute inline-flex w-2 h-2 rounded-full opacity-75 top-1 left-1 bg-hmio-blue"></div>
            </div>
            Live Data Active
          </div>
          <div className="inline-block w-[1px] h-6 bg-hmio-black-900 pb-1 mx-4 md:hidden" />
          <div className="flex items-center justify-center flex-1 md:flex-none text-hmio-white md:hidden">
            <SolanaLogo className="mr-2" />
            <span>
              {candlestick?.close
                ? formatDollarsString(
                    formatNum(
                      convertFractionToUSDC(candlestick.close),
                      2,
                      false,
                    ),
                  )
                : "Loading"}
            </span>
          </div>
        </div>
        <div className="hidden md:inline-block w-[1px] h-6 bg-hmio-black-900 pb-1 mx-4" />
        <div className="flex items-center w-full mb-4 md:w-auto md:mb-0">
          <div className="flex items-center justify-center flex-1 md:flex-none text-hmio-white">
            <span className="text-hmio-black-400 mr-1.5">24h NFT Vol.</span>
            {nftMarketVolume1D ? (
              <>
                <span>{nftMarketVolume1D.split(" ")[0]}</span>
                <span className="ml-0.5">◎</span>
              </>
            ) : (
              "Loading"
            )}
          </div>
        </div>
        <div className="hidden md:inline-block w-[1px] h-6 bg-hmio-black-900 pb-1 mx-4" />
        <div className="items-center justify-center flex-1 hidden md:flex-none text-hmio-white md:flex">
          <SolanaLogo className="mr-2" />
          <span>
            {candlestick?.close
              ? formatDollarsString(
                  formatNum(convertFractionToUSDC(candlestick.close), 2, false),
                )
              : "Loading"}
          </span>
        </div>
        {/* <div className="flex items-center w-full mb-4 md:w-auto md:mb-0">
          <div className="hidden md:inline-block w-[1px] h-6 bg-hmio-black-900 pb-1 mx-4" />
          <div className="flex justify-center flex-1 md:block text-hmio-white">
            <span className="text-hmio-black-400 mr-1.5">24h Swap Vol.</span>
            <span>{swapVolume1D ?? "Loading"}</span>
          </div>
        </div> */}
        {/* Below is some duplicated elements to give the illusion of infinite scroll */}
        <div className="flex justify-center w-full md:hidden md:mb-0">
          <div className="flex items-center justify-center flex-1 md:flex-none text-hmio-white">
            <div className="relative w-4 h-4 mr-2">
              <div className="absolute inline-flex w-2 h-2 rounded-full opacity-75 top-1 left-1 animate-live-data-pulse bg-hmio-blue"></div>
              <div className="absolute inline-flex w-2 h-2 rounded-full opacity-75 top-1 left-1 bg-hmio-blue"></div>
            </div>
            Live Data Active
          </div>
          <div className="inline-block w-[1px] h-6 bg-hmio-black-900 pb-1 mx-4" />
          <div className="flex items-center justify-center flex-1 w-full text-hmio-white">
            <SolanaLogo className="mr-2" />
            <span>
              {candlestick?.close
                ? formatDollarsString(
                    formatNum(
                      convertFractionToUSDC(candlestick.close),
                      2,
                      false,
                    ),
                  )
                : "Loading"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
