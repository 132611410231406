import type {
  LastNrtBlockIdsResponse,
} from "model/aggregations/nrt/types";
import useClient from "./useClient";
import useSWR from "swr";

type UseLastNrtBlockIdsOptions = {
  // The collection ID or token mint the NRT data is relevant for.
  identifier: string;
};

export function useLastNrtBlockIds({
  identifier,
}: UseLastNrtBlockIdsOptions): LastNrtBlockIdsResponse | undefined {
  const aggregations = useClient("aggregations");
  const { data } = useSWR(["/nrt/lastNrtBlockIds", identifier], async ([uri, identifier]) => {
    return await aggregations.lastNrtBlockIds({ params: { identifier }});
  });

  return data;
}
