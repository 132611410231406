export const SOL_MULTIPLIER = 0.000000001;
export const USDC_DECIMALS = 6;
export const USDC_MINT_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
// 1 USDC is 1 USDC by definition.
export const USDC_PRICE_IN_USDC = 1;
export const WSOL_ADDRESS = "So11111111111111111111111111111111111111112"; // wSOL

export const convertLamportsToSol = (v: string | number) =>
  Number(v) * SOL_MULTIPLIER;

export const getPortionPercentage = (numerator: number, denominator: number) =>
  numerator / denominator;

export const convertFromFractionalUnits = (
  v: string | number,
  decimals: number,
): number => {
  return Number(v) * Math.pow(10, -1 * decimals);
};

export const convertToUsd = (v: string | number) =>
  convertFromFractionalUnits(Number(v), USDC_DECIMALS);
